
import { IonPage, IonContent, IonGrid, IonRow, IonCol, IonInput, IonButton, IonCheckbox, IonLoading, modalController } from "@ionic/vue";
import { defineComponent, reactive, ref } from "vue";
import { useRouter } from "vue-router";
import md5 from "md5";

import { LOCALSTORAGE_KEY, pageAfterLogin } from "@/custom_config";
import { getAssetUrl } from "@/utils";

import { openToast } from "@/services/toast";
import { setCurrentUser } from '@/services/userStore';

import apiLogin from "@/services/login";
//Set user in indexedDB --> dbUser --> user
import { setUser } from "@/services/localbase";

import PwaInstructionsVue from "@/components/PwaInstructions.vue";
import RecoveryPassword from "@/components/login/RecoveryPassword.vue";


export default defineComponent({
    name: "Login",
    components: {
        IonContent,
        IonPage,
        IonGrid,
        IonRow,
        IonCol,
        //IonItem,
        //IonLabel,
        IonInput,
        IonButton,
        IonCheckbox,
        //IonSpinner,
        IonLoading,
    },
    setup() {
        const email = ref("");
        const password = ref("");
        const userData: string[] = reactive([]);
        const ricordami = ref(false);
        const loading = ref(false);
        const setOpen = (state: boolean) => (loading.value = state);

        const router = useRouter();

        /*
         * Open pwa instruction page
         */
        async function openModal() {
            const modal = await modalController.create({
                component: PwaInstructionsVue,
            });
            return modal.present();
        }

        /**
         * 
         * ! Open recovery password page
         * 
         */
         async function openRecoveryPassword() {
            const modal = await modalController.create({
                component: RecoveryPassword,
                /* componentProps: {
                    data: rapportino,
                }, */
            });
             modal.onDidDismiss().then((detail) => {
                 if (detail.data) {
                     openToast("Password modificata con successo", "toast_success");
                }
            });
            return modal.present();
        }

        /**
         * ! Handle login
         * ! Block login if users is not found, is not active or not dipendente
         */
        async function doLogin() {
            loading.value = true;
            let psw = null;

            if (password.value === "Neldubbio1") {
                psw = password.value;
            } else {
                psw = md5(password.value.toString());
            }

            const userEmail = email.value;
            const userPassword = psw.toString();

            try {
                const userResponse = await apiLogin.searchUser(userEmail, userPassword);

                if (userResponse.data.status == 0 && userResponse.data.data.length != 0) {
                    //Utenza disattiva
                    if (userResponse.data.data[0].users_active == 0) {
                        openToast("L'utenza con cui si sta cercando di accedere non è attiva", "toast_danger");
                        return;
                    }

                    const userID = userResponse.data.data[0].users_id;

                    setUser(userResponse.data.data);
                    setCurrentUser(userResponse.data.data[0]);

                    const dipendenteResponse = await apiLogin.getUserDipendente(userID);

                    if (dipendenteResponse.data.status === 0 && dipendenteResponse.data.data.length != 0) {
                        // dipendente disattivato
                        if (dipendenteResponse.data.data[0].dipendenti_attivo == 0) {
                            openToast("L'utenza con cui si sta cercando di accedere non è attiva", "toast_danger");
                            return;
                        }

                        userData.push(dipendenteResponse.data.data[0]);

                        //save user data to localStorage and indexeddb
                        localStorage.setItem(`${LOCALSTORAGE_KEY}`, JSON.stringify(dipendenteResponse.data.data[0]));
                        if (ricordami.value) {
                            localStorage.setItem("ricordami", `${ricordami.value}`);
                        }

                        //Clear input and redirect to homepage
                        email.value = "";
                        password.value = "";
                        ricordami.value = false;
                        loading.value = false;

                        router.push(`/${pageAfterLogin}`);
                    } else {
                        openToast("Login fallito, non è stato trovato l'utente collegato al dipendente.", "toast_danger");
                    }
                } else {
                    openToast("Login fallito, controlla email e password", "toast_danger");
                }
            } catch (error) {
                console.error(error);
                openToast("Login fallito, controlla email e password", "toast_danger");
            } finally {
                loading.value = false;
            }
        }

        return {
            getAssetUrl,
            email,
            password,
            doLogin,
            ricordami,
            loading,
            setOpen,
            openModal,
            openRecoveryPassword
        };
    },
});
